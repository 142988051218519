/* eslint-disable @next/next/no-sync-scripts */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import striptags from 'striptags';
import Head from 'next/head';

import { AnalyticsContext, RouteContext } from '@/context';
import { capitalize } from '@/utils/misc-utils';
import { getAuthors, getCMSTags } from '@/services/ad.js';
import { gtm_enabled } from '@/constants';
import {
  jsProfilerAsString,
  loadAbandonmentAsString,
} from '@buzzfeed/performance';
import gdpr from '@buzzfeed/adlib/dist/module/services/consent';
import { adsContextAsString } from '@buzzfeed/adlib/bindings/react/utils.js';
import { APSTAG_SNIPPET } from '@buzzfeed/adlib/bindings/react/constants/snippets.js';
import { getAdCallCategory } from '@buzzfeed/adlib/services/ad-call-utils';
import { useDeepLink } from '@/hooks/useDeepLink';
import { getUserGeoCountry } from '@buzzfeed/bf-utils/lib/localization';
import { getPoe, getWatsonTagsTargeting, getPageSessionId } from '@buzzfeed/adlib/dist/module/services/page-targeting';

import LinkTags from './LinkTags';
import MetaTags from './MetaTags';
import StructuredData from './StructuredData';

const WINDOW_GLOBALS = `
  window.BF_STATIC = {};
  window.BZFD = {
    Context: { page: {} },
    Config: {}
  };
  window.bfa = function() {
    console.group('Dummy bfa global called with');
    var args = Array.prototype.slice.call(arguments);
    console.log(args[0]);
    console.log(JSON.stringify(args[1]));
    console.groupEnd();
  };
`;

/* GOOGLE TAG MANAGER */
const GTM_GEOS = ['AU', 'NZ', 'GB', 'IE'];
const GTM_IDS = {
  'default': 'GTM-PDTMMZD9',
  'Australia': 'GTM-NDT8NFTH',
  'United Kingdom': 'GTM-PDTMMZD9',
};

const getGTMSnippet = (userGeo, content) => {
  let GTM_ID = GTM_IDS['default'];
  let dataLayerCode = '';

  if (userGeo === 'AU' || userGeo === 'NZ') {
    GTM_ID = GTM_IDS['Australia'];
    dataLayerCode = `
    window.dataLayer = window.dataLayer || [];
    dataLayer.push({
      'articleSection': '${content?.classification?.section || content.pageType}',
      'keywords': '${content?.keywords?.split(', ') || content?.tags?.map(item => item?.display_name || item).join(',') || ''}',
      'pageType': '${content.pageType}',
      'pagePath': '${content.pagePath}'
    });`;
  } else if (userGeo === 'GB' || userGeo === 'IE') {
    GTM_ID = GTM_IDS['United Kingdom'];
  }

  return `
  ${dataLayerCode}
  (function(w,d,s,l,i){
    w[l] = w[l]||[];
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l!='dataLayer'?'&l='+l:'';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j,f);
  })(window, document, 'script', 'dataLayer', '${GTM_ID}');`;
}

/**
 * Get the string representation of the connatix player script that helps with any chrome heavy ad issues
 */
const CONNATIX_SNIPPET = `!function(n){if(!window.cnx){window.cnx={},window.cnx.cmd=[];var t=n.createElement('iframe');t.src="javascript:false";t.display='none',t.onload=function(){var n=t.contentWindow.document,c=n.createElement('script');c.src='//cd.connatix.com/connatix.player.js?cid=1bf35e8b-3d55-48a6-aef8-0ebfdd277df7',c.setAttribute('async','1'),c.setAttribute('type','text/javascript'),n.body.appendChild(c)},n.head.appendChild(t)}}(document)`; // eslint-disable-line quotes

const getTitle = (pageType, name) => {
  let title = 'Tasty - Food videos and recipes';
  if (pageType === 'compilation') {
    title = name.includes('Recipes') || name.includes('recipes')
      ? name : `${name} | Recipes`;
  } else if (pageType === 'recipe') {
    // get first 200 characters
    title = name ? name.substring(0, 200) : null;
    title = title ? `${title} ${title.toLowerCase().endsWith('recipe') ? '' : 'Recipe '}by Tasty` : 'Recipe by Tasty';
  } else if (pageType === 'submit-recipe') {
    title = 'Submit Your Own Tasty Recipe';
  } else if (pageType === 'feature') {
    title = `${name} – Tasty`;
  } else if (pageType === 'ingredients') {
    title = 'Recipes by Ingredients';
  } else if (pageType === 'tag') {
    title = `${name} Recipes`;
  } else if (pageType === 'search') {
    title = `${capitalize(name)} Recipes Search | Tasty`;
  } else if (['ingredient', 'topic'].includes(pageType)) {
    title = `${name} ${name.toLowerCase().endsWith('recipes') ? '' : 'Recipes '}- Tasty`;
  } else if (pageType === 'article') {
    title = name;
  } else if (pageType === 'articles') {
    title = name;
  } else if (pageType === 'guides') {
    title = name;
  } else if (pageType === 'accessibility') {
    title = name;
  } else if (pageType === 'tastyvalues') {
    title = name;
  } else if (pageType === 'mycart') {
    title = name;
  } else if (pageType === 'my-meal-plan-my-way' || pageType === 'lidl-fiver-feast' || pageType === 'summer-of-beans') {
    title = name;
  } else if (pageType === 'contest') {
    title = 'Select Your Prize!';
  } else if (pageType === 'in-app-only') {
    title = name;
  } else if (pageType === 'zelle-savor-the-city') {
    title = name;
  } else if (pageType === 'tastynewsletter') {
    title = name;
  } else if (pageType === 'tastysubscriptions') {
    title = name;
  }
  return striptags(title);
};

const getDescription = (pageType, content) => {
  let description = 'The official home of all things Tasty, the world’s largest food network. Search, watch, and cook every single Tasty recipe and video ever - all in one place!';
  if (content.description) {
    // prefer seo_description or harded description if explicitly provided
    description = content.description;
  } else if (pageType === 'compilation') {
    /**
      * Given compilation children recipes, create a list of all recipe names.
      * Then create a string from this list joined by commas. Now construct our
      * description string by proceeding it with "Featuring "
      * This string will be used for SEO purposes in the meta tags
      */
    const names = content.recipes.items.map((item) => item.name);
    description = `Featuring ${names.slice(0, -1).join(', ')} and ${names.slice(-1)}`;
  } else if (pageType === 'recipe' && content.ingredient_sections) {
    /**
      * Use a recipe's ingredients to format the description
      */
    const ingredientList = [];
    content.ingredient_sections.forEach((section) => {
      section.ingredients.forEach((ingredient) => {
        ingredientList.push(ingredient.name);
      });
    });
    description = `Here's what you need: ${ingredientList.join(', ')}`;
  } else if (pageType === 'submit-recipe') {
    description = "Tasty's recipe submission form. Tasty is looking for new recipes and wants to feature yours on the Tasty website!";
  } else if (['ingredient', 'topic'].includes(pageType)) {
    const recipes = content.items.slice(0, 3).map((item) => item.name);
    description = pageType === 'topic'
      ? `Looking for ${content.name}${content.name.toLowerCase().endsWith('recipes') ? '' : ' Recipes'}? `
      : `Looking for recipes with ${content.name.toLowerCase()}? `;
    description += `Check out some of our favorites here including ${recipes.join(', ')}, and more!`;
  }

  return striptags(description);
};

const getPoeData = () => {
  if (typeof window === 'undefined') {
    return '';
  }
  return getPoe({
    env: {
      isBPage: false, // the isBPage is always `false` on Tasty
    }
  });
};

function PageHead({ content = {}, breadcrumbs = [] }) {
  const analyticsInfo = useContext(AnalyticsContext);
  const { context_page_id, context_page_type, page_edition } = analyticsInfo;
  const { pageType, queryParams, pagePath } = useContext(RouteContext);
  const description = getDescription(pageType, content);
  // title is separate because that version is used in structured data, regardless of custom seo_title
  const title = getTitle(pageType, content.name);
  const seoTitle = content.seo_title || title;
  const seoCanonicalURL = content.seo_canonical_url || content.canonical_url;
  const id = content.id || null;
  let hasVideo = false;
  const userGeo = getUserGeoCountry();

  const { deepLink } = useDeepLink({
    destination: 'connatix',
    devices: context_page_type === 'recipe' ? ['ios', 'android'] : [],
    linkPath: `${context_page_type}/${context_page_id}`,
    restrictSize: false,
  });

  const metaTagProps = {
    description,
    id,
    title,
    thumbnailUrl: content.thumbnail_url,
    twitterHandle: content.twitter_handle,
  };
  if (pageType === 'recipe') {
    /**
      * specifically used for whether to display branchIO on recipes.
      *   It should display for all recipes with a video id (even if a comp kid that
      *   displays an image - so use video_id as check instead of non_video_recipe AND type image
      */
    metaTagProps.videoUrl = content.non_video_recipe.status ? null : content.video_url;
    const shouldDisplayDeepLink = !(content.non_video_recipe.status && content.non_video_recipe.type === 'image');
    if (shouldDisplayDeepLink) {
      metaTagProps.deepLink = deepLink;
    }
  } else if (pageType === 'compilation') {
    metaTagProps.videoUrl = content.video_url;
  }
  hasVideo = !!metaTagProps.videoUrl;

  return (
    <React.Fragment>
      {/* metatags first */}
      <MetaTags {...metaTagProps}/>
      {/* links after meta tags, includes preconnect/dns-prefetch */}
      <LinkTags hasVideo={hasVideo} url={pageType === 'article' ? seoCanonicalURL : ''}/>
      <Head>
        {/* inline JS */}
        <script
          key="load-abandonment"
          dangerouslySetInnerHTML={{
            __html: loadAbandonmentAsString({
              context_page_id,
              context_page_type,
              destination: 'tasty',
              page_edition,
              source: 'web_tasty',
            }),
          }}
        />
        {/* Google Tag Manager (SEO) */}
        {gtm_enabled && GTM_GEOS.includes(userGeo) &&
          <script
            key="google-tag-manager"
            dangerouslySetInnerHTML={{ __html: getGTMSnippet(userGeo, {...content, pagePath, pageType}) }}
          />
        }
        <script
          key="js-profiling"
          dangerouslySetInnerHTML={{ __html: jsProfilerAsString() }}
        />
        <script key="globals" dangerouslySetInnerHTML={{ __html: WINDOW_GLOBALS }} />
        <script
          key="ads-context"
          dangerouslySetInnerHTML={{
            __html: adsContextAsString({
              authors: getAuthors(content),
              cms_tags: getCMSTags({...content, pageType}),
              dfp_keyword: striptags(queryParams['dfp-keyword']) || null,
              pagetype: null,
              id,
              section: ['tasty', pageType],
              video_id: content.video_id || null,
              zone3: getAdCallCategory({}, { env: {
                pageCategory: pageType,
                pageSection: pageType,
              }}),
              isTasty: true,
              tastyPageType: pageType,
              title: title,
              poe: getPoeData(),
              watsonTargeting: getWatsonTagsTargeting({laserTags: content?.laser_tags || {}}),
              getPageSessionId: getPageSessionId({gdpr})
            }),
          }}
        />
        <script key="connatix-player" dangerouslySetInnerHTML={{ __html: CONNATIX_SNIPPET }} />
        <script key="apstag" dangerouslySetInnerHTML={{ __html: APSTAG_SNIPPET }} />

        {/* Linked scripts `<script src=""></script>` */}
        {/* temp remove statically loading GPT to test "other' browser classiciation RT-1408; still preload */}
        <link rel="preload" src="https://securepubads.g.doubleclick.net/tag/js/gpt.js" />

        {/* title */}
        <title>{seoTitle}</title>
      </Head>
      <StructuredData
        content={content}
        description={description}
        title={title}
        breadcrumbs={breadcrumbs}
      />
    </React.Fragment>
  );
}

PageHead.propTypes = {
  content: PropTypes.object,
};

export default PageHead;
